<template>
  <div class="home">

    <div class="home-intro">

      <div class="row row-gutter-20">
        <div class="col-8">
          <h1>Mit InTime flexibel und zuverlässig von A nach B.</h1>
          <p class="lead">Nutzen Sie unsere Webseite und buchen Sie ganz flexibel Ihr Wunschfahrzeug. Profitieren Sie von unseren günstigen Preisen und unserem erstklassigen Service. Wir sind Ihr zuverlässiger Partner rund um die Vermietung von Fahrzeugen.</p>
        </div>
        <div class="col-16">

          <div class="home-reservation">

            <!--<ul class="nav nav-categories cf">
              <li v-for="group in vehicle_groups" v-bind:key="group.id">
                <a @click="select_group(group)" :class="check_group_classes(group)">{{ group.name }}</a>
              </li>
            </ul>-->

            <div class="form-wrap select-wrap">
              <label for="pickup_location">Abholung und Rückgabe in</label>
              <select v-model="pickup_location" id="pickup_location">
                <option v-for="location in locations" v-bind:key="location.id" v-bind:value="location.id">
                  {{ location.name }}
                </option>
              </select>
            </div>

            <div class="row row-gutter-20">
              <div class="col-12">
                <div class="row row-gutter-20">
                  <div class="col-12">
                    <div class="form-wrap select-wrap">
                      <label for="pickup_date">Abholung Datum</label>
                      <v-date-picker v-model="pickup_date" :min-date='new Date()' :first-day-of-week="2" locale="de" :disabled-dates='{ weekdays: [1] }'>
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            class="form-input"
                            :value="inputValue"
                            v-on="inputEvents"
                          />
                        </template>
                      </v-date-picker>
                    </div>
                  </div>
                  <div class="col-12">

                    <div class="form-wrap select-wrap">
                      <label for="pickup_time">Abholung Uhrzeit</label>
                      <select v-model="pickup_time" id="pickup_time">
                        <option v-for="hour in pickup_hours" v-bind:value="hour.hour_time_val" v-bind:key="hour.id">{{ hour.hour_time_formatted }} Uhr</option>
                      </select>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row row-gutter-20">
                  <div class="col-12">

                    <div class="form-wrap select-wrap">
                      <label for="return_date">Rückgabe Datum</label>
                      <v-date-picker v-model="return_date" :min-date='this.pickup_date' :disabled-dates='{ weekdays: [1] }' :first-day-of-week="2" locale="de">
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            class="form-input"
                            :value="inputValue"
                            v-on="inputEvents"
                          />
                        </template>
                      </v-date-picker>
                    </div>

                  </div>
                  <div class="col-12">

                    <div class="form-wrap select-wrap">
                      <label for="return_time">Rückgabe Uhrzeit</label>
                      <select v-model="return_time" id="return_time">
                        <option value="00">00:00 Uhr</option>
                        <option value="01">01:00 Uhr</option>
                        <option value="02">02:00 Uhr</option>
                        <option value="03">03:00 Uhr</option>
                        <option value="04">04:00 Uhr</option>
                        <option value="05">05:00 Uhr</option>
                        <option value="06">06:00 Uhr</option>
                        <option value="07">07:00 Uhr</option>
                        <option value="08">08:00 Uhr</option>
                        <option value="09">09:00 Uhr</option>
                        <option value="10">10:00 Uhr</option>
                        <option value="11">11:00 Uhr</option>
                        <option value="12">12:00 Uhr</option>
                        <option value="13">13:00 Uhr</option>
                        <option value="14">14:00 Uhr</option>
                        <option value="15">15:00 Uhr</option>
                        <option value="16">16:00 Uhr</option>
                        <option value="17">17:00 Uhr</option>
                        <option value="18">18:00 Uhr</option>
                        <option value="19">19:00 Uhr</option>
                        <option value="20">20:00 Uhr</option>
                        <option value="21">21:00 Uhr</option>
                        <option value="22">22:00 Uhr</option>
                        <option value="23">23:00 Uhr</option>
                      </select>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <p style="margin-top:0"><a @click="checkVehicles" class="button button-orange">Jetzt Fahrzeug auswählen ›</a></p>

          </div>
        </div>
      </div>

    </div>

    <div class="home-featured row row-gutter-10">
      <div class="col-8">
        <div class="home-entry">
          <h3>Transporter mieten</h3>
          <p class="price">Ab 50 € / Tag</p>
          <p class="discount"><span class="material-icons">new_releases</span><span>AKTION</span></p>
          <p><router-link to="/reservations/new" class="button button-orange button-small">Jetzt reservieren ›</router-link></p>
        </div>
      </div>
      <div class="col-8">
        <div class="home-entry">
          <h3>PKW mieten</h3>
          <p class="price">Ab 24 € / Tag</p>
          <p class="discount"><span class="material-icons">new_releases</span><span>AKTION</span></p>
          <p><router-link to="/reservations/new" class="button button-orange button-small">Jetzt reservieren ›</router-link></p>
        </div>
      </div>
      <div class="col-8">
        <div class="home-entry">
          <h3>Langzeitmiete gewünscht?</h3>
          <p class="price">Angebot sichern</p>
          <p class="discount"><span class="material-icons">new_releases</span><span>AKTION</span></p>
          <p><router-link to="/contact" class="button button-orange button-small">Jetzt kontaktieren ›</router-link></p>
        </div>
      </div>
    </div>

    <div class="home-advantages">
      <h3 style="margin-bottom:50px">Warum InTime?</h3>
      <div class="row row-gutter-10">
        <div class="col-6">
          <p class="icon"><span class="material-icons">support</span></p>
          <h4>24/7 für Sie da</h4>
        </div>
        <div class="col-6">
          <p class="icon"><span class="material-icons">local_offer</span></p>
          <h4>Die besten Preise</h4>
        </div>
        <div class="col-6">
          <p class="icon"><span class="material-icons">fact_check</span></p>
          <h4>Keine versteckten Gebühren</h4>
        </div>
        <div class="col-6">
          <p class="icon"><span class="material-icons">book_online</span></p>
          <h4>Einfach online buchen</h4>
        </div>
      </div>
    </div>

    <div class="home-about">

      <h3>Über InTime Autovermietung</h3>

      <p>Willkommen bei der InTime Autovermietung. Wir sind Ihre zuverlässige und günstige Autovermietung in Schorndorf, Schwäbisch Gmünd, Plüderhausen und Fellbach. Egal ob es für einen Umzug ein Transporter oder übers Wochenende ein PKW sein soll, bei uns finden Sie das passende Fahrzeug zu einem unschlagbar günstigen Preis. Oder soll es ein Transporter für eine gewerbliche Nutzung sein? Kontaktieren Sie uns für ein individuelles Angebot. Suchen Sie entweder einfach online oder rufen uns unter unserer 24h Hotline an. Sie können auch direkt unsere Standorte telefonisch oder per E-Mail erreichen.</p>
      <p>Entscheiden Sie sich für InTime, Ihrer zuverlässigen und günstigen Autovermietung. </p>

      <h4>Unsere Standorte</h4>
      <div class="row row-gutter-20">
        <div v-for="location in locations" v-bind:key="location.id" class="col-6">
          <router-link :to="'/locations/'+location.id" class="home-location">
            <div class="location-img">
              <img v-if="location.image" :src="location.image" width="100%" height="150">
            </div>
            <div class="location-body">
              <h3>{{ location.name }}</h3>
              <p><span class="material-icons">gps_fixed</span><span>{{ location.street_name }} {{ location.street_number }}</span></p>
              <p><span>{{ location.zip_code }} {{ location.city }}</span></p>
              <p><span class="material-icons">phone</span><span>{{ location.contact_phone }}</span></p>
              <p><span class="material-icons">mail</span><span>{{ location.contact_email }}</span></p>
            </div>
          </router-link>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import router from '../router'
import moment from 'moment'

export default {
  name: 'home',
  data () {
    return {
      locations: [],
      vehicle_groups: [],
      vehicle_group: 0,
      pickup_location: 1,
      pickup_date: "",
      pickup_hours: [],
      pickup_time: "08",
      return_location: 1,
      return_date: "",
      return_hours: [],
      return_time: "16"
    }
  },
  methods: {
    set_dates() {
      this.pickup_date = new Date();
      this.return_date = new Date();
    },
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/locations', {})
      .then(response => {
        this.locations = response.data.locations;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/vehicle_groups', {})
      .then(response => {
        this.vehicle_groups = response.data.vehicle_groups;
      })
      .catch(error => {
        console.log(error);
      })

      this.get_pickup_hours(new Date());
      this.get_return_hours(new Date());

      var date = new Date();
      date.setHours(date.getHours() + Math.round(date.getMinutes()/60));
      date.setMinutes(0, 0, 0);
      var time = moment(date).format("HH");
      this.pickup_time = time;
    },
    checkVehicles() {
      router.push('/reservations/new?home=true&pickup_location='+this.pickup_location+'&return_location='+this.return_location+'&pickup_date='+moment(this.pickup_date).format("YYYY-MM-DD")+'&pickup_time='+this.pickup_time+'&return_date='+moment(this.return_date).format("YYYY-MM-DD")+'&return_time='+this.return_time)
    },
    check_group_classes(group) {
      if (this.vehicle_group == group.id) {
        return 'active';
      }
    },
    change_pickup_location() {
      console.log(this.pickup_location);
    },
    get_pickup_hours(hour_day) {

      var hd = moment(hour_day).format("dddd").toLowerCase();

      axios.get(process.env.VUE_APP_BASE_API+'/v1/locations/'+this.pickup_location+'/get_hours?hour_day='+hd, {})
      .then(response => {
        this.pickup_hours = response.data.business_hours;
      })
      .catch(error => {
        console.log(error);
      })

    },
    get_return_hours(hour_day) {

      var hd = moment(hour_day).format("dddd").toLowerCase();

      axios.get(process.env.VUE_APP_BASE_API+'/v1/locations/'+this.return_location+'/get_hours?hour_day='+hd, {})
      .then(response => {
        this.return_hours = response.data.business_hours;
      })
      .catch(error => {
        console.log(error);
      })

    },
  },
  mounted () {
    this.set_dates();
    this.get_data();
  },
  watch: {
    pickup_date: function(val) {
      this.get_pickup_hours(val);
    },
    pickup_location: function(val) {
      this.return_location = val;
    },
    return_date: function(val) {
      this.get_return_hours(val);
    },
  }
}
</script>
